span {
    color: #031655;
}
div.row{
    background-color: #f8f4ea;
}

div.color{
    background-color: #f8f4ea;
}

.productItems {
    align-content: center;
    align-items: center;
}